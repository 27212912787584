import React from 'react'
import Layout from "../components/layout";

let getDodgePenalties = (dodgeCount) => {
  if (dodgeCount === 0) {
    return {lp: 3, queueLockout: "6 minutes"}
  } else if (dodgeCount === 1) {
    return {lp: 10, queueLockout: "30 minutes"}
  } else if (dodgeCount >= 2) {
    return {lp: 10, queueLockout: "24 hours"}
  }
}

let formatDate = (date) => {
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  return `${addLeadingZeros(month)}/${addLeadingZeros(day)}/${year}`
}

let formatTime = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  return `${addLeadingZeros(hours)}:${addLeadingZeros(minutes)}:${addLeadingZeros(seconds)}`
}

let addLeadingZeros = (value) => {
  value = String(value)
  while (value.length < 2) {
    value = '0' + value
  }
  return value
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      resetTriggered: false,
      dodgeCount: 0,
      dodgeTime: 0,
      dodgedTimeout: null,
      dodgedTimeoutDate: null,
      dodgedTimeoutTime: null,
      usedSite: false,
      dodgePenalties: getDodgePenalties(0),
      timerState: {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
      }
    }
  }

  componentDidMount() {
    let dodgeCount = parseInt(localStorage.getItem("dodgeCount")) || 0
    let dodgedTimeoutDate = localStorage.getItem("dodgedTimeoutDate") || 0
    let dodgedTimeoutTime = localStorage.getItem("dodgedTimeoutTime") || 0
    this.setState({
      dodgeCount: dodgeCount,
      dodgedTimeoutDate: dodgedTimeoutDate,
      dodgedTimeoutTime: dodgedTimeoutTime,
      dodgePenalties: getDodgePenalties(dodgeCount),
      usedSite: localStorage.getItem("usedSite") === "true" || false
    })
    if (dodgedTimeoutTime !== 0) {
      this.startTimer(dodgedTimeoutDate, dodgedTimeoutTime)
    }
  }

  resetDodgeTimer = (e) => {
    this.setState({resetTriggered: true})
    localStorage.removeItem("dodgedTimeoutDate")
    localStorage.removeItem("dodgedTimeoutTime")
    this.stopTimer();
}

  startTimer(timeoutDate, timeoutTime) {
    const endDate = `${timeoutDate} ${timeoutTime}`

    // Initialize countdown for the first time
    let countdown = this.calculateCountdown(endDate)
    this.setState({timerState: countdown})

    // Update every second
    this.interval = setInterval(() => {
      const countdown = this.calculateCountdown(endDate);
      countdown ? this.setState({timerState: countdown}) : this.stopTimer();
    }, 1000)
  }

  stopTimer() {
    clearInterval(this.interval);
    localStorage.removeItem("dodgeCount")
    this.setState({
      dodgeCount: 0,
      dodgedTimeout: null,
      dodgePenalties: getDodgePenalties(0),
      timerState: {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
      }
    })
  }

  calculateCountdown(endDate) {

    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000

    // clear countdown when date is reached
    if (diff <= 0) return false

    const timeLeft = {
      running: true,
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    }

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400))
      diff -= timeLeft.years * 365.25 * 86400
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400)
      diff -= timeLeft.days * 86400
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600)
      diff -= timeLeft.hours * 3600
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60)
      diff -= timeLeft.min * 60
    }
    timeLeft.sec = diff
    return timeLeft
  }

  getDodgeTimeout = () => {
    let dodgeTimeout = new Date();
    dodgeTimeout.setDate((dodgeTimeout.getDate()+1));
    dodgeTimeout.setMinutes((dodgeTimeout.getMinutes() - this.state.dodgeTime))
    return dodgeTimeout
  }

  handleIDodgedClick = (e) => {
    let currentDodgeCount = this.state.dodgeCount + 1
    localStorage.setItem("dodgeCount", currentDodgeCount.toString())

    if (currentDodgeCount === 1) {
      let dodgedAt = this.getDodgeTimeout()
      let formattedTimeoutDate = formatDate(dodgedAt)
      let formattedTimeoutTime = formatTime(dodgedAt)
      localStorage.setItem("dodgedTimeoutDate", formattedTimeoutDate)
      localStorage.setItem("dodgedTimeoutTime", formattedTimeoutTime)
      localStorage.setItem("usedSite", "true")
      this.setState({
        dodgedTimeoutDate: formattedTimeoutDate,
        dodgedTimeoutTime: formattedTimeoutTime,
        usedSite: true,
        resetTriggered: false
      })
      this.startTimer(formattedTimeoutDate,  formattedTimeoutTime);
    }

    this.setState(
        {
          dodgeTime: "0",
          dodgeCount: currentDodgeCount,
          dodgePenalties: getDodgePenalties(currentDodgeCount)
        }
    )
  }

  handleDodgeTimeChange = (e) => {
    this.setState({dodgeTime: e.target.value})
  }

  render() {
    return(
      <Layout>
        <div className="bgimg" >
          <div className="bg-layout">
            <div className="middle">
              <div className="content">
                  <h1 className="h1">
                  {this.state.dodgeCount === 0  && this.state.usedSite === false && "Can I Dodge?" }
                  {this.state.dodgeCount === 0  && this.state.usedSite === true && <span style={{color: "greenyellow"}}>You Can Dodge!</span> }
                  {this.state.dodgeCount > 0  && <span style={{color: "red"}}>You Can't Dodge!</span> }
                </h1>
                {this.state.usedSite === true && this.state.dodgeCount === 0 && this.state.resetTriggered === false && this.state.dodgedTimeoutDate !== 0 ?
                    <p className={"LocalTime"}>Your Dodge Reset At: {this.state.dodgedTimeoutDate} {this.state.dodgedTimeoutTime}</p>: null
                }
                {this.state.dodgeCount > 0 && (
                  <React.Fragment>
                    <h5>Your Dodge Will Reset In</h5>
                    <div className="Countdown">
                      {this.state.timerState.years > 0 && (
                          <span className="Countdown-col">
                            <span className="Countdown-col-element">
                              <strong>{addLeadingZeros(this.state.timerState.years)}</strong>
                              <span>{this.state.timerState.years === 1 ? 'Year' : 'Years'}</span>
                            </span>
                          </span>
                        )}
                        {this.state.timerState.days > 0 && (
                          <span className="Countdown-col">
                          <span className="Countdown-col-element">
                            <strong>{addLeadingZeros(this.state.timerState.days)}</strong>
                            <span>{this.state.timerState.days === 1 ? 'Day' : 'Days'}</span>
                          </span>
                        </span>
                        )}
                        <span className="Countdown-col">
                          <span className="Countdown-col-element">
                            <strong>{addLeadingZeros(this.state.timerState.hours)}</strong>
                            <span>{this.state.timerState.hours === 1 ? 'Hour' : 'Hours'}</span>
                          </span>
                        </span>
                        <span className="Countdown-col">
                          <span className="Countdown-col-element">
                            <strong>{addLeadingZeros(this.state.timerState.min)}</strong>
                            <span>Min</span>
                          </span>
                        </span>
                        <span className="Countdown-col">
                          <span className="Countdown-col-element">
                            <strong>{addLeadingZeros(this.state.timerState.sec)}</strong>
                            <span>Sec</span>
                          </span>
                        </span>
                        <p className={"LocalTime"}>{this.state.dodgedTimeoutDate} {this.state.dodgedTimeoutTime}</p>
                    </div>
                    <h6>You can leave and come back when you want to check how long until you can dodge again</h6>
                  </React.Fragment>
                )}
                {this.state.dodgeCount === 0 && (
                    <p>Click the button below when you dodge and check back here to see when you can dodge again.</p>
                )}
                {this.state.dodgeCount < 2 && (
                    <button className="button" onClick={this.handleIDodgedClick}>{this.state.dodgeCount === 0 ? "I Dodged": "I Dodged Again"}</button>
                )}
                {this.state.dodgeCount === 1 && (
                    <span>&nbsp;</span>
                )}
                {this.state.dodgeCount === 0 && (
                  <React.Fragment>
                    <br/>
                    <select value={this.state.dodgeTime} onChange={this.handleDodgeTimeChange}>
                      <option value="0">Just Then</option>
                      <option value="5">5 Minutes Ago</option>
                      <option value="10">10 Minutes Ago</option>
                      <option value="30">30 Minutes Ago </option>
                      <option value="60">1 Hour Ago </option>
                      <option value="120">2 Hours Ago </option>
                      <option value="360">6 Hours Ago </option>
                      <option value="720">12 Hours Ago </option>
                    </select>
                  </React.Fragment>
                )}
                {this.state.dodgeCount > 0 && (
                    <React.Fragment>
                      <button className="button" onClick={this.resetDodgeTimer}>Reset</button>
                      <p className={"DodgePenalty"}><em>
                        {`If you dodge again you will lose ${this.state.dodgePenalties.lp}LP and be locked out of queue for  ${this.state.dodgePenalties.queueLockout}.`}
                      </em></p>
                    </React.Fragment>
                )  }
              </div>
            </div>
            <div className="bottomleft">
              <div className={"PersonalInfo"}><a href="https://twitter.com/beartime234?lang=en">@Beartime234</a> made with <span style={{color: "red"}}>❤</span></div>
            </div>
            <div className="bottomright">
              <div className={"PersonalInfo"}>"I don't need to use your shitty little site" - T1.</div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
